@import '../../style/themes/default';
@import '../../style/mixins';

@imagePickerPrefixCls: am-image-picker;

.@{imagePickerPrefixCls} {
  &-list {
    padding: @v-spacing-md @h-spacing-md 0;
    margin-bottom: @h-spacing-lg;

    .am-flexbox {
      margin-bottom: @v-spacing-sm;

      .am-flexbox-item {
        position: relative;
        margin-right: @h-spacing-sm;
        margin-left: 0;

        &:after {
          display: block;
          content: ' ';
          padding-bottom: 100%;
        }
      }
    }

    .@{imagePickerPrefixCls}-item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;

      .@{imagePickerPrefixCls}-item-remove {
        width: @icon-size-xxs;
        height: @icon-size-xxs;
        position: absolute;
        right: 6 * @hd;
        top: 6 * @hd;
        text-align: right;
        vertical-align: top;
        z-index: 2;
        background-size: @icon-size-xxs auto;
        .encoded-svg-background('image_picker_delete');
      }

      .@{imagePickerPrefixCls}-item-content {
        height: 100%;
        width: 100%;
        border-radius: @radius-sm;
        background-size: cover;
      }

      img {
        width: 100%;
      }
    }

    .@{imagePickerPrefixCls}-upload-btn {
      box-sizing: border-box;
      border-radius: @radius-sm;
      border: @border-width-md solid @border-color-base;
      background-color: @fill-base;

      &:before,
      &:after {
        width: @border-width-md;
        height: 25 * @hd;
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: @color-icon-base;
      }

      &:after {
        width: 25 * @hd;
        height: @border-width-md;
      }

      &-active {
        background-color: @fill-tap;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
      }
    }
  }
}

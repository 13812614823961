@import '../../style/mixins';
@import '../../style/themes/default';

@indexedList: am-indexed-list;

.@{indexedList} {
  &-section-body.am-list-body,
  &-section-body.am-list-body .am-list-item:last-child .am-list-line {
    .hairline-remove('bottom');
  }

  &-section-header {
    &.am-list-body,
    &.am-list-body .am-list-item .am-list-line {
      .hairline-remove('bottom');
    }

    .am-list-item {
      height: @list-title-height;
      min-height: @list-title-height;
      background-color: @fill-body;

      .am-list-line {
        height: @list-title-height;
        min-height: @list-title-height;
      }

      .am-list-content {
        font-size: @font-size-base !important;
        color: @color-text-caption !important;
      }
    }
  }

  &-quick-search-bar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 0;
    text-align: center;
    color: @color-link;
    font-size: @font-size-caption;
    list-style: none;
    padding: 0;

    li {
      padding: 0 @h-spacing-sm;
    }

    &-over {
      background-color: @fill-mask;
    }
  }

  &-qsindicator {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15 * @hd auto auto -30 * @hd;
    width: 60 * @hd;
    height: 30 * @hd;
    background: transparent;
    opacity: 0.7;
    color: #0af;
    font-size: 20 * @hd;
    border-radius: 30 * @hd;
    z-index: 1999;
    text-align: center;
    line-height: 30 * @hd;

    &-hide {
      display: none;
    }
  }
}

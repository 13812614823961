@import '../../style/mixins';
@import '../../style/themes/default';

@switchPrefixCls: am-switch;

@switch-width: 51 * @hd;
@switch-height: 31 * @hd;
@switch-border: 2 * @hd;

.@{switchPrefixCls} {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  align-self: center;

  .checkbox {
    width: 51 * @hd;
    height: 31 * @hd;
    border-radius: 31 * @hd;
    box-sizing: border-box;
    background: #e5e5e5;
    z-index: 0;
    margin: 0;
    padding: 0;
    appearance: none;
    border: 0;
    cursor: pointer;
    position: relative;
    transition: all 300ms;

    &:before {
      content: ' ';
      position: absolute;
      left: @switch-border;
      top: @switch-border;
      width: @switch-width - 2 * @switch-border;
      height: @switch-height - 2 * @switch-border;
      border-radius: @switch-height - 2 * @switch-border;
      box-sizing: border-box;
      background: @fill-base;
      z-index: 1;
      transition: all 200ms;
      transform: scale(1);
    }

    &:after {
      content: ' ';
      width: @switch-height - 2 * @switch-border;
      height: @switch-height - 2 * @switch-border;
      border-radius: @switch-height - 2 * @switch-border;
      background: @fill-base;
      position: absolute;
      z-index: 2;
      top: @switch-border;
      left: @switch-border;

      transform: translateX(0);
      transition: all 200ms;
      box-shadow:
        0 0 2 * @hd 0 rgba(0, 0, 0, 0.2),
        0 2 * @hd 11.5 * @hd 0 rgba(0, 0, 0, 0.08),
        -1 * @hd 2 * @hd 2 * @hd 0 rgba(0, 0, 0, 0.1);
    }

    &.checkbox-disabled {
      z-index: 3;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0 none;
    appearance: none;

    &:checked {
      & + .checkbox {
        background: @switch-fill;

        &:before {
          transform: scale(0);
        }

        &:after {
          transform: translateX(20 * @hd);
        }
      }
    }

    &:disabled {
      & + .checkbox {
        opacity: @opacity-disabled;
      }
    }
  }

  &&-android {
    .checkbox {
      width: 72 * @hd;
      height: 23 * @hd;
      border-radius: @radius-sm;
      background: #a7aaa6;

      &:before {
        display: none;
      }

      &:after {
        width: 35 * @hd;
        height: 21 * @hd;
        border-radius: @radius-xs;
        box-shadow: none;
        left: 1PX;
        top: 1PX;
      }
    }

    input[type="checkbox"] {
      &:checked {
        & + .checkbox {
          background: @switch-fill-android;

          &:before {
            transform: scale(0);
          }

          &:after {
            transform: translateX(35 * @hd);
          }
        }
      }
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@navbar-height: 45 * @hd;
@navbarPrefixCls: am-navbar;

.@{navbarPrefixCls} {
  display: flex;
  align-items: center;
  height: @navbar-height;
  background-color: @brand-primary;
  color: @fill-base;

  &-left,
  &-title,
  &-right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-left {
    padding-left: @h-spacing-lg;
    font-size: @link-button-font-size;

    &-icon {
      margin-right: @h-spacing-sm;
      display: inherit;
    }
  }

  &-title {
    justify-content: center;
    font-size: 18 * @hd;
    white-space: nowrap;
  }

  &-right {
    justify-content: flex-end;
    font-size: @link-button-font-size;
    margin-right: @h-spacing-lg;
  }

  &-light {
    background-color: @fill-base;
    color: @brand-primary;
  }

  &-light &-title {
    color: @color-text-base;
  }
}

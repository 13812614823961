@import '../../style/themes/default';
@import '../../style/mixins';

@activityIndicatorPrefixCls: am-activity-indicator;

.@{activityIndicatorPrefixCls} {
  display: flex;
  align-items: center;
  z-index: 99;

  &-spinner {
    display: inline-block;
    width: 20 * @hd;
    height: 20 * @hd;
    .encoded-svg-background('loading');

    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    animation: spinner-anime 1s linear infinite;
  }

  &-tip {
    font-size: @font-size-base;
    margin-left: @h-spacing-md;
    color: @color-text-base;
    opacity: 0.4;
  }

  &.@{activityIndicatorPrefixCls}-toast {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: @toast-zindex;

    .@{activityIndicatorPrefixCls}-spinner {
      margin: 0;
    }

    .@{activityIndicatorPrefixCls}-toast {
      display: inline-block;
      position: relative;
      top: 4 * @hd;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: @v-spacing-lg @h-spacing-lg;
    border-radius: @radius-lg;
    background-clip: padding-box;
    color: @color-text-base-inverse;
    background-color: @toast-fill;
    font-size: @font-size-subhead;
    line-height: 20 * @hd;
  }

  &-spinner-lg {
    width: 32 * @hd;
    height: 32 * @hd;
  }

  @keyframes spinner-anime {
    100% {
      transform: rotate(360deg);
    }
  }
}

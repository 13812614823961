@import '../../style/mixins';
@import '../../style/themes/default';

@listPrefixCls: am-list;
@checkboxWarpPrefixCls: am-checkbox;
@checkboxInnerPrefixCls: ~"@{checkboxWarpPrefixCls}-inner";

.@{checkboxWarpPrefixCls} {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: @icon-size-sm;
  height: @icon-size-sm;

  &-inner {
    position: absolute;
    right: 0;
    width: @icon-size-sm;
    height: @icon-size-sm;
    border: 1 * @hd solid #ccc;
    border-radius: @radius-circle;
    transform: rotate(0deg);
    box-sizing: border-box;

    &:after {
      position: absolute;
      display: none;
      top: 1.5 * @hd;
      right: 6 * @hd;
      z-index: 999;
      width: 5 * @hd;
      height: 11 * @hd;
      border-style: solid;
      border-width: 0 1 * @hd 1 * @hd 0;
      content: '\0020';
      transform: rotate(45deg);
    }
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0 none;
    appearance: none;
  }

  &.@{checkboxWarpPrefixCls}-checked {
    .@{checkboxInnerPrefixCls} {
      border-color: @brand-primary;
      background: @brand-primary;

      &:after {
        display: block;
        border-color: #fff;
      }
    }
  }

  &.@{checkboxWarpPrefixCls}-disabled {
    opacity: @opacity-disabled;

    &.@{checkboxWarpPrefixCls}-checked {
      .@{checkboxInnerPrefixCls} {
        border-color: @color-text-caption;
        background: none;

        &:after {
          border-color: @color-text-caption;
        }
      }
    }
  }
}

.@{listPrefixCls} {
  & &-item {
    &.@{checkboxWarpPrefixCls}-item {
      .@{listPrefixCls}-thumb {
        width: @icon-size-sm;
        height: @icon-size-sm;

        .@{checkboxWarpPrefixCls} {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: @list-item-height;

          &-inner {
            left: @h-spacing-lg;
            top: 12 * @hd;
          }
        }
      }

      &.@{checkboxWarpPrefixCls}-item-disabled {
        .@{listPrefixCls}-content {
          color: @color-text-disabled;
        }
      }
    }
  }
}

.@{checkboxWarpPrefixCls}-agree {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-left: @h-spacing-lg;
  padding-top: @v-spacing-md;
  padding-bottom: @v-spacing-md;

  .@{checkboxWarpPrefixCls} {
    position: absolute;
    left: 0;
    top: 0;
    width: 2 * @h-spacing-lg;
    height: 100%;

    &-inner {
      left: 0;
      top: @v-spacing-xs + @v-spacing-md;
    }
  }

  .@{checkboxWarpPrefixCls}-agree-label {
    display: inline-block;
    font-size: @font-size-subhead;
    color: @color-text-base;
    line-height: @line-height-paragraph;
    margin-left: 2 * @h-spacing-lg;
    margin-top: 1PX;
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@popoverPrefixCls: am-popover;

@import './base';

.@{popoverPrefixCls}-inner {
  font-size: @font-size-subhead;
  color: @color-text-base;
  background-color: @fill-base;
  border-radius: @radius-sm;
  box-shadow: 0 0 2 * @hd rgba(0, 0, 0, 0.21);
  overflow: hidden; // for popover item active background color not overflow

  &-wrapper {
    position: relative;
    background-color: @fill-base;
  }
}

.@{popoverPrefixCls} .@{popoverPrefixCls}-item {
  padding: 0 @h-spacing-md;

  &-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 39 * @hd;
    box-sizing: border-box;
    padding: 0 @h-spacing-md;
  }
  &:not(:first-child) {
    .@{popoverPrefixCls}-item-container {
      .hairline('top');
    }
  }

  &.@{popoverPrefixCls}-item-active {
    .@{popoverPrefixCls}-item-container {
      .hairline-remove('top');
    }
  }

  &.@{popoverPrefixCls}-item-active + .@{popoverPrefixCls}-item .@{popoverPrefixCls}-item-container {
    .hairline-remove('top');
  }

  &.@{popoverPrefixCls}-item-active {
    background-color: @border-color-base;
  }

  &.@{popoverPrefixCls}-item-active.@{popoverPrefixCls}-item-fix-active-arrow {
    position: relative;
  }

  &.@{popoverPrefixCls}-item-disabled {
    color: @color-text-disabled;

    &.@{popoverPrefixCls}-item-active {
      background-color: transparent;
    }
  }

  &-icon {
    margin-right: @h-spacing-md;
    width: @icon-size-xs;
    height: @icon-size-xs;
  }
}

@import '../../style/themes/default';
@import '../../style/mixins';

@sliderPrefixCls: am-slider;

.borderBox() {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari
}

.@{sliderPrefixCls} {
  position: relative;

  &-rail {
    position: absolute;
    width: 100%;
    background-color: @border-color-base;
    height: 2 * @hd;
    box-sizing: border-box;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 2 * @hd;
    border-radius: @radius-xs;
    background-color: @brand-primary;
  }

  &-handle {
    position: absolute;
    margin-left: -12 * @hd;
    margin-top: -10 * @hd;
    width: 22 * @hd;
    height: 22 * @hd;
    cursor: pointer;
    border-radius: @radius-circle;
    border: @border-width-lg solid @brand-primary;
    background-color: @fill-base;
    box-sizing: border-box;

    &:focus {
      background-color: tint(@brand-primary, 20%);
    }
  }

  &-mark {
    position: absolute;
    top: 20 * @hd;
    left: 0;
    width: 100%;
    font-size: @font-size-caption-sm;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: @color-text-base;

    &-active {
      opacity: @opacity-disabled;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4 * @hd;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -5 * @hd;
    margin-left: -4 * @hd;
    width: 12 * @hd;
    height: 12 * @hd;
    border: @border-width-lg solid @border-color-base;
    background-color: @fill-base;
    cursor: pointer;
    border-radius: @radius-circle;
    vertical-align: middle;

    &:first-child {
      margin-left: -4 * @hd;
    }

    &:last-child {
      margin-left: -4 * @hd;
    }

    &-active {
      border-color: @brand-primary;
    }
  }

  &-disabled {
    opacity: @opacity-disabled;

    .@{sliderPrefixCls}-track {
      height: 2 * @hd;
    }

    .@{sliderPrefixCls}-handle,
    .@{sliderPrefixCls}-mark-text,
    .@{sliderPrefixCls}-dot {
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}

@import 'variables';

.@{stepsPrefixCls}-label-vertical {
  .@{stepsPrefixCls}-item {
    overflow: visible;
    &-tail {
      padding: 0 24 * @hd;
      margin-left: 48 * @hd;
    }
    &-content {
      display: block;
      text-align: center;
      margin-top: 8 * @hd;
      width: @stepDescriptionMaxWidth;
    }
    &-icon {
      display: inline-block;
      margin-left: 36 * @hd;
    }
    &-title {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
}

@import '../../style/themes/default';
@import "../../style/mixins";

@stepperPrefixCls: am-stepper;

.@{stepperPrefixCls} {
  position: relative;
  margin: 0;
  padding: 2 * @hd 0;
  display: inline-block;
  box-sizing: content-box;
  width: 63 * @hd;
  height: 35 * @hd;
  line-height: 35 * @hd;
  font-size: @font-size-base;
  vertical-align: middle;
  overflow: hidden;

  &-handler-wrap {
    position: absolute;
    width: 100%;
    font-size: 24 * @hd;
  }

  &-handler,
  &-handler-up-inner,
  &-handler-down-inner {
    width: 30 * @hd;
    height: 30 * @hd;
    line-height: 30 * @hd;
  }

  &-handler {
    text-align: center;
    border: @border-width-md solid @border-color-base;
    border-radius: @radius-md;
    overflow: hidden;
    color: @color-text-base;
    position: absolute;
    display: inline-block;
    box-sizing: content-box;

    &-active {
      z-index: 2;
      background-color: @fill-tap;
    }
  }

  &-handler-up-inner,
  &-handler-down-inner {

    user-select: none;
    right: 2 * @hd;
    color: @color-text-base;
  }

  &-input-wrap {
    display: none;
    width: 100%;
    height: 30 * @hd;
    line-height: 30 * @hd;
    text-align: center;
    overflow: hidden;
  }

  &-input {
    display: none;
    width: 60 * @hd;
    font-size: 16 * @hd;
    color: @color-text-base;
    text-align: center;
    border: 0;
    padding: 0;
    background: none;
    vertical-align: middle;

    &[disabled] {
      opacity: 1;
      color: @color-text-base;
    }
  }

  &.showNumber {
    width: 138 * @hd;

    .@{stepperPrefixCls}-input-wrap {
      display: inline-block;
    }

    .@{stepperPrefixCls}-input {
      display: inline-block;
    }

    .@{stepperPrefixCls}-handler-down-disabled {
      right: -1PX;
    }
  }

  &-handler-up {
    cursor: pointer;
    right: 0;

    &-inner {
      &:before {
        text-align: center;
        content: "+";
      }
    }
  }

  &-handler-down {
    cursor: pointer;
    left: 0;

    &-inner {
      &:before {
        text-align: center;
        content: "-";
      }
    }
  }

  &-handler-down-disabled,
  &-handler-up-disabled {
    opacity: @opacity-disabled;
  }

  &-handler-up-disabled &-handler-active {
    background: none;
  }

  &-disabled {
    .@{stepperPrefixCls}-handler-down,
    .@{stepperPrefixCls}-handler-up {
      opacity: @opacity-disabled;
      background: none;
    }
  }

  &-disabled {
    .@{stepperPrefixCls}-handler {
      opacity: @opacity-disabled;
    }

    .@{stepperPrefixCls}-input-wrap {
      opacity: @opacity-disabled;
    }
  }
}

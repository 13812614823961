@import '../../style/mixins';
@import '../../style/themes/default';
@drawerPrefixCls: am-drawer;

.@{drawerPrefixCls} {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &-sidebar {
    z-index: 4;
    position: absolute;
    transition: transform .3s ease-out;
    will-change: transform;
    overflow-y: auto;
  }

  &-draghandle {
    z-index: 1;
    position: absolute;
    background-color: rgba(50, 50, 50, .1);
  }

  &-overlay {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out;
    background-color: @fill-mask;
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    transition: left .3s ease-out, right .3s ease-out;
  }

  &&-left,
  &&-right {
    .@{drawerPrefixCls}-sidebar,
    .@{drawerPrefixCls}-draghandle {
      top: 0;
      bottom: 0;
    }

    .@{drawerPrefixCls}-draghandle {
      width: 10 * @hd;
      height: 100%;
    }
  }

  &&-top,
  &&-bottom {
    .@{drawerPrefixCls}-sidebar,
    .@{drawerPrefixCls}-draghandle {
      left: 0;
      right: 0;
    }

    .@{drawerPrefixCls}-draghandle {
      width: 100%;
      height: 10 * @hd;
    }
  }

  &&-left {
    .@{drawerPrefixCls}-sidebar {
      left: 0;
      transform: translateX(-100%);

      .@{drawerPrefixCls}-open& {
        box-shadow: 1PX 1PX 2 * @hd rgba(0, 0, 0, 0.15);
      }
    }

    .@{drawerPrefixCls}-draghandle {
      left: 0;
    }
  }

  &&-right {
    .@{drawerPrefixCls}-sidebar {
      right: 0;
      transform: translateX(100%);

      .@{drawerPrefixCls}-open& {
        box-shadow: -1PX 1PX 2 * @hd rgba(0, 0, 0, 0.15);
      }
    }

    .@{drawerPrefixCls}-draghandle {
      right: 0;
    }
  }

  &&-top {
    .@{drawerPrefixCls}-sidebar {
      top: 0;
      transform: translateY(-100%);

      .@{drawerPrefixCls}-open& {
        box-shadow: 1PX 1PX 2 * @hd rgba(0, 0, 0, 0.15);
      }
    }

    .@{drawerPrefixCls}-draghandle {
      top: 0;
    }
  }

  &&-bottom {
    .@{drawerPrefixCls}-sidebar {
      bottom: 0;
      transform: translateY(100%);

      .@{drawerPrefixCls}-open& {
        box-shadow: 1PX -1PX 2 * @hd rgba(0, 0, 0, 0.15);
      }
    }

    .@{drawerPrefixCls}-draghandle {
      bottom: 0;
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@tagPrefixCls: am-tag;

.@{tagPrefixCls} {
  display: inline-block;
  position: relative;
  font-size: @font-size-base;
  text-align: center;
  padding: 0 @h-spacing-lg;
  height: @tag-height;
  line-height: @tag-height;
  box-sizing: border-box;

  &&-small {
    height: @tag-height-sm;
    line-height: @tag-height-sm;
    padding: 0 @h-spacing-sm;
    font-size: @font-size-icontext;
  }

  &-normal {
    background-color: @fill-base;
    color: @color-text-caption;
    .hairline('all',  @border-color-base, @radius-sm);
  }

  &-active {
    background-color: @fill-base;
    color: @tag-color;
    .hairline('all', @tag-color, @radius-sm);
  }

  &-disabled {
    color: @color-text-disabled;
    background-color: @fill-disabled;
    .hairline('all', @fill-disabled, @radius-sm);
  }

  &-close {
    position: absolute;
    top: -9 * @hd;
    left: -10 * @hd;
    color: @color-text-placeholder;

    &-active {
      color: @color-text-caption;
    }

    .am-icon {
      background-color: @fill-base;
      border-radius: 9 * @hd;
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@pickerPrefixClass: am-progress;

.@{pickerPrefixClass} {
  &-outer {
    background-color: @border-color-base;
    display: block;
  }

  &-fixed-outer {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: @progress-zindex;
  }

  &-hide-outer {
    background-color: transparent;
  }

  &-bar {
    border: @border-width-lg solid @brand-primary;
    transition: all .3s linear 0s;
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';
@buttonPrefixCls: am-button;

.@{buttonPrefixCls} {
  // do not use width:100%, can not set margin
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  font-size: @button-font-size;
  height: @button-height;
  line-height: @button-height;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  // default
  color: @color-text-base;
  background-color: @fill-base;

  .hairline('all', @border-color-base, @radius-md);

  // Multiple buttons inline arranged, the last one border-right may not display
  &-borderfix {
    &:before {
      transform: scale(0.49)!important;
    }
  }

  &&-active {
    background-color: @fill-tap;
  }
  &&-disabled {
    color: fade(@color-text-base, 30%);
    opacity: 0.6;
  }

  &-primary {
    color: @color-text-base-inverse;
    background-color: @primary-button-fill;
    .hairline('all', @primary-button-fill, @radius-md);

    &.@{buttonPrefixCls}-active {
      color: fade(@color-text-base-inverse, 30%);
      background-color: @primary-button-fill-tap;
    }
    &.@{buttonPrefixCls}-disabled {
      color: fade(@color-text-base-inverse, 60%);
      opacity: 0.4;
    }
  }

  &-ghost {
    color: @ghost-button-color;
    background-color: transparent;
    .hairline('all', @ghost-button-color, @radius-md);

    &.@{buttonPrefixCls}-active {
      color: @ghost-button-fill-tap;
      background-color: transparent;
      .hairline('all', @ghost-button-fill-tap, @radius-md);
    }
    &.@{buttonPrefixCls}-disabled {
      color: fade(@color-text-base, 10%);
      .hairline('all', fade(@color-text-base, 10%), @radius-md);
      opacity: 1; // override default opacity: 0.6
    }
  }

  &-warning {
    color: @color-text-base-inverse;
    background-color: @warning-button-fill;

    &.@{buttonPrefixCls}-active {
      color: fade(@color-text-base-inverse, 30%);
      background-color: @warning-button-fill-tap;
    }
    &.@{buttonPrefixCls}-disabled {
      color: fade(@color-text-base-inverse, 60%);
      opacity: 0.4;
    }
  }

  &-inline {
    display: inline-block;
    padding: 0 @h-spacing-lg;
    &.@{buttonPrefixCls}-icon {
      display: inline-flex;
    }
  }

  &-small {
    font-size: @button-font-size-sm;
    height: @button-height-sm;
    line-height: @button-height-sm;
    padding: 0 @h-spacing-lg;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .@{buttonPrefixCls}-icon {
    margin-right: 0.5em;
  }
}

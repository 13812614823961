@import '../../style/themes/default';

@wingblankPrefixCls: am-wingblank;

.@{wingblankPrefixCls} {
  margin-left: @h-spacing-md;
  margin-right: @h-spacing-md;

  &&-sm {
    margin-left: @h-spacing-sm;
    margin-right: @h-spacing-sm;
  }

  &&-md {
    margin-left: @h-spacing-md;
    margin-right: @h-spacing-md;
  }

  &&-lg {
    margin-left: @h-spacing-lg;
    margin-right: @h-spacing-lg;
  }
}

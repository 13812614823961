@import '../../style/mixins';
@import '../../style/themes/default';

@listPrefixCls: am-list;
@inputPrefixCls: am-input;
@keyboardPrefixCls: am-number-keyboard;

.@{listPrefixCls}-item {
  .@{inputPrefixCls}-control {
    .fake-input-container {
      height: 30 * @hd;
      line-height: 30 * @hd;
      position: relative;

      .fake-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-right: 2 * @hd;
        margin-right: 3 * @hd;
        text-decoration: rtl;
        text-align: right;
        color: #000;
        font-size: 17 * @hd;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.fake-input-disabled {
          color: @color-text-disabled;
        }

        &.focus {
          transition: color .2s;

          &::before {
            content: "";
          }

          &::after {
            content: "";
            position: absolute;
            right: 1.5 * @hd;
            top: 10%;
            height: 80%;
            border-right: 1.5 * @hd solid @keyboard-confirm-color;
            animation: keyboard-cursor infinite 1s step-start;
          }
        }
      }

      .fake-input-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: @color-text-placeholder;
        text-align: right;
      }
      &-left {
        .fake-input {
          text-align: left;
          &.focus:after {
            position: static;
          }
        }
        .fake-input-placeholder {
          text-align: left;
        }
      }
    }
  }
}

.@{keyboardPrefixCls}-wrapper {
  &.@{keyboardPrefixCls}-wrapper-hide {
    bottom: -500 * @hd;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10000;
  font-family: 'PingFang SC';
  background-color: #f6f6f7;
  transition-duration: 0.2s;
  transition-property: transform display;
  transform: translateZ(0);
  padding-bottom: env(safe-area-inset-bottom);

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    .hairline('top');

    tr {
      width: 100%;
      padding: 0;
      margin: 0;

      .@{keyboardPrefixCls}-item {
        width: 25%;
        padding: 0;
        margin: 0;
        height: 50 * @hd;
        text-align: center;
        &:not(.keyboard-confirm) {
          .hairline('left');
          .hairline('bottom');
        }
        font-size: 25.5 * @hd;
        color: #2a2b2c;
        position: relative;

        &.@{keyboardPrefixCls}-item-active {
          background-color: @fill-tap;
        }

        &.keyboard-confirm {
          color: #fff;
          font-size: 21 * @hd;
          background-color: @keyboard-confirm-color;
          .hairline('bottom');
          &.@{keyboardPrefixCls}-item-active {
            background-color: @keyboard-confirm-tap-color;
          }

          &.@{keyboardPrefixCls}-item-disabled {
            background-color: @keyboard-confirm-tap-color;
            color: rgba(255, 255, 255, 0.45);
          }
        }

        &.keyboard-delete {
          .encoded-svg-background('input_item_kb_backspace');
          background-size: 25.5 * @hd 18.5 * @hd;
          background-position: 50% 50%;
          background-repeat: no-repeat;
        }

        &.keyboard-hide {
          .encoded-svg-background('input_item_kb_hide');
          background-size: 32.5 * @hd 23.5 * @hd;
          background-position: 50% 50%;
          background-repeat: no-repeat;
        }
      }
      .@{keyboardPrefixCls}-item-disabled {
        color: #bbb;
      }
    }
  }
}

@keyframes keyboard-cursor {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

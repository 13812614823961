@import '../../style/mixins';
@import '../../style/themes/default';

@accordionPrefixCls: am-accordion;

.@{accordionPrefixCls} {
  position: relative;
  .hairline('top');

  &-anim-active {
    transition: all .2s ease-out;
  }

  & &-item {
    .@{accordionPrefixCls}-header {
      position: relative;
      color: @color-text-base;
      font-size: @font-size-heading;
      height: @list-item-height;
      line-height: @list-item-height;
      background-color: @fill-base;
      box-sizing: content-box;
      overflow: hidden;
      padding-left: @h-spacing-lg;
      padding-right: 2 * @h-spacing-lg;
      .hairline('bottom');
      .ellipsis();

      i {
        position: absolute;
        display: block;
        top: @h-spacing-lg;
        right: @h-spacing-lg;
        width: @icon-size-xxs;
        height: @icon-size-xxs;
        .encoded-svg-background('right');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transform: rotate(90deg);
        transition: transform .2s ease;
      }

      &[aria-expanded~="true"] {
        i {
          transform: rotate(270deg);
        }
      }
    }

    .@{accordionPrefixCls}-content {
      overflow: hidden;
      background: @fill-base;

      .@{accordionPrefixCls}-content-box {
        font-size: @font-size-subhead;
        color: @color-text-paragraph;
        position: relative;
        .hairline('bottom');

        .am-list-body {
          .hairline-remove('top');
        }
      }

      &.@{accordionPrefixCls}-content-inactive {
        display: none;
      }
    }
  }
}

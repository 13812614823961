@import '../../style/themes/default';

@whitespacePrefixCls: am-whitespace;

.@{whitespacePrefixCls} {
  &&-xs {
    height: @v-spacing-xs;
  }

  &&-sm {
    height: @v-spacing-sm;
  }

  &&-md {
    height: @v-spacing-md;
  }

  &&-lg {
    height: @v-spacing-lg;
  }

  &&-xl {
    height: @v-spacing-xl;
  }
}

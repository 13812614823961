@import '../../style/mixins';
@import '../../style/themes/default';

@listPrefixCls: am-list;

.@{listPrefixCls} {
  &-header {
    padding: @v-spacing-lg @h-spacing-lg @v-spacing-md @h-spacing-lg;
    font-size: @font-size-base;
    color: @color-text-caption;
    width: 100%;
    box-sizing: border-box;
  }

  &-footer {
    padding: @v-spacing-md @h-spacing-lg @v-spacing-lg @h-spacing-lg;
    font-size: @font-size-base;
    color: @color-text-caption;
  }

  &-body {
    position: relative;
    background-color: @fill-base;
    .hairline('top');
    .hairline('bottom');

    div:not(:last-child) {
      .@{listPrefixCls}-line {
        .hairline('bottom');
      }
    }
  }
}

.@{listPrefixCls}-item {
  position: relative;
  display: flex;
  padding-left: @h-spacing-lg;
  min-height: @list-item-height;
  background-color: @fill-base;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  align-items: center;

  .@{listPrefixCls}-ripple {
    position: absolute;
    background: transparent;
    display: inline-block;
    overflow: hidden;
    will-change: box-shadow, transform;
    transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
    outline: none;
    cursor: pointer;
    border-radius: 100%;
    transform: scale(0);

    &.@{listPrefixCls}-ripple-animate {
      background-color: hsla(0, 0%, 62%, .2);
      animation: ripple 1s linear;
    }
  }

  &.@{listPrefixCls}-item-top {
    .@{listPrefixCls}-line {
      align-items: flex-start;

      .@{listPrefixCls}-arrow {
        margin-top: 2 * @hd;
      }
    }
  }

  &.@{listPrefixCls}-item-middle {
    .@{listPrefixCls}-line {
      align-items: center;
    }
  }

  &.@{listPrefixCls}-item-bottom {
    .@{listPrefixCls}-line {
      align-items: flex-end;
    }
  }

  &.@{listPrefixCls}-item-error {
    .@{listPrefixCls}-line {
      .@{listPrefixCls}-extra {
        color: #f50;

        .@{listPrefixCls}-brief {
          color: #f50;
        }
      }
    }
  }

  &.@{listPrefixCls}-item-active {
    background-color: @fill-tap;
  }

  &&-disabled {
    .@{listPrefixCls}-line {
      .@{listPrefixCls}-content,
      .@{listPrefixCls}-extra {
        color: @color-text-disabled;
      }
    }
  }

  img {
    width: @icon-size-md;
    height: @icon-size-md;
    vertical-align: middle;
  }

  /* list左图片显示*/
  .@{listPrefixCls}-thumb {
    &:first-child {
      margin-right: @h-spacing-lg;
    }

    &:last-child {
      margin-left: @h-spacing-md;
    }
  }

  .@{listPrefixCls}-line {
    position: relative;
    display: flex;
    flex: 1;
    align-self: stretch;
    padding-right: @h-spacing-lg;
    overflow: hidden;

    /* list左侧主内容*/
    .@{listPrefixCls}-content {
      flex: 1;
      color: @color-text-base;
      font-size: @font-size-heading;
      line-height: @line-height-paragraph;
      text-align: left;
      .ellipsis();

      padding-top: 7 * @hd;
      padding-bottom: 7 * @hd;
    }

    /* list右补充内容*/
    .@{listPrefixCls}-extra {
      flex-basis: 36%;
      color: @color-text-caption;
      font-size: @font-size-caption;
      line-height: @line-height-paragraph;
      text-align: right;
      .ellipsis();

      padding-top: 7 * @hd;
      padding-bottom: 7 * @hd;
    }

    .@{listPrefixCls}-title {
      .ellipsis();
    }

    /* 辅助性文字*/
    .@{listPrefixCls}-brief {
      color: @color-text-caption;
      font-size: @font-size-subhead;
      line-height: @line-height-paragraph;
      margin-top: @v-spacing-sm;
      .ellipsis();
    }

    /* list右侧箭头*/
    .@{listPrefixCls}-arrow {
      display: block;
      width: @icon-size-xxs;
      height: @icon-size-xxs;
      margin-left: @h-spacing-md;
      .encoded-svg-background('right');

      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      visibility: hidden;

      &-horizontal {
        visibility: visible;
      }

      &-vertical {
        visibility: visible;
        transform: rotate(90deg);
      }

      &-vertical-up {
        visibility: visible;
        transform: rotate(270deg);
      }
    }

    &-multiple {
      padding: 12.5 * @hd @h-spacing-lg 12.5 * @hd 0;

      .@{listPrefixCls}-content {
        padding-top: 0;
        padding-bottom: 0;
      }

      .@{listPrefixCls}-extra {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &-wrap {
      .@{listPrefixCls}-content {
        white-space: normal;
      }

      .@{listPrefixCls}-extra {
        white-space: normal;
      }
    }
  }

  select {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    font-size: @font-size-heading;
    appearance: none;
    background-color: transparent;
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

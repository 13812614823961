@import '../../style/themes/default';
@import '../../style/mixins/util';

@noticeBarPrefixCls: am-notice-bar;

@icon-trips: '<svg width="38" height="33" viewBox="0 0 38 33" xmlns="http://www.w3.org/2000/svg"><title>trips</title><g fill-rule="evenodd"><path d="M17.838 28.8c-.564-.468-1.192-.983-1.836-1.496-4.244-3.385-5.294-3.67-6.006-3.67-.014 0-.027.005-.04.005-.015 0-.028-.005-.042-.005H3.562c-.734 0-.903-.203-.903-.928V10.085c0-.49.058-.8.66-.8h5.782c.693 0 1.758-.28 6.4-3.628.828-.597 1.637-1.197 2.336-1.723V28.8zM19.682.19c-.463-.22-1.014-.158-1.417.157-.02.016-1.983 1.552-4.152 3.125C10.34 6.21 9.243 6.664 9.02 6.737H3.676c-.027 0-.053.003-.08.004H1.183c-.608 0-1.1.486-1.1 1.085V25.14c0 .598.492 1.084 1.1 1.084h8.71c.22.08 1.257.55 4.605 3.24 1.947 1.562 3.694 3.088 3.712 3.103.25.22.568.333.89.333.186 0 .373-.038.55-.116.48-.213.79-.684.79-1.204V1.38c0-.506-.294-.968-.758-1.19z" mask="url(#mask-2)"/><path d="M31.42 16.475c0-3.363-1.854-6.297-4.606-7.876-.125-.066-.42-.192-.625-.192-.612 0-1.108.488-1.108 1.09 0 .404.22.764.55.952 2.128 1.19 3.565 3.442 3.565 6.025 0 2.627-1.486 4.913-3.677 6.087-.318.19-.53.54-.53.934 0 .602.496 1.09 1.107 1.09.26.002.568-.15.568-.15 2.835-1.556 4.754-4.538 4.754-7.96" mask="url(#mask-4)"/><g><path d="M30.14 3.057c-.205-.122-.41-.22-.658-.22-.608 0-1.1.485-1.1 1.084 0 .433.26.78.627.977 4.043 2.323 6.762 6.636 6.762 11.578 0 4.938-2.716 9.248-6.755 11.572-.354.19-.66.55-.66.993 0 .6.494 1.084 1.102 1.084.243 0 .438-.092.65-.213 4.692-2.695 7.848-7.7 7.848-13.435 0-5.723-3.142-10.718-7.817-13.418" mask="url(#mask-6)"/></g></g></svg>';

.@{noticeBarPrefixCls} {
  background-color: @notice-bar-fill;
  height: @notice-bar-height;
  overflow: hidden;
  font-size: @font-size-base;
  line-height: @notice-bar-height;
  color: @notice-bar-color;
  display: flex;

  &-content {
    flex: 1;
    width: 100%;
    margin: auto @h-spacing-lg;
    .ellipsis();
  }

  &-icon {
    margin-left: @h-spacing-lg;
    display: flex;
    align-items: center;

    .@{noticeBarPrefixCls}-trips {
      .encoded-svg-background-i(@icon-trips);
    }

    & + div {
      margin-left: @h-spacing-sm;
    }
  }

  &-operation {
    display: flex;
    align-items: center;
    padding-right: @h-spacing-md;
  }
}

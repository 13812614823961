@import '../../style/themes/default';
@import '../../style/mixins';

@tabbar-prefix-cls: am-tab-bar;
@tab-bar-item-cls: am-tab-bar-item;
@tabs-prefix-cls: am-tabs;

.@{tabbar-prefix-cls} {
  height: 100%;
  overflow: hidden;

  &-bar {
    position: relative;
    box-sizing: border-box;
    height: @tab-bar-height;
    .hairline('top');
    width: 100%;
    display: flex;
    transition-duration: .2s;
    transition-property: height bottom;
    z-index: 100;
    justify-content: space-around;
    align-items: center;
    bottom: 0;

    &-hidden-top {
      bottom: @tab-bar-height;
      height: 0;
    }

    &-hidden-bottom {
      bottom: -@tab-bar-height;
      height: 0;
    }

    .@{tabbar-prefix-cls}-tab {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      &-image {
        width: @icon-size-md;
        height: @icon-size-md;
        vertical-align: middle;
      }

      &-title {
        font-size: @font-size-icontext;
        margin: @v-spacing-xs 0 0 0;
        line-height: @line-height-base;
        text-align: center;
      }

      &-icon {
        display: flex;
        justify-content: center;

        .tab-badge {
           :last-child {
            margin-top: 4 * @hd;
            left: @icon-size-md;
          }
        }

        .tab-dot {
           :last-child {
            margin-top: 4 * @hd;
            left: @icon-size-md;
          }
        }
      }
    }
  }
}

.@{tab-bar-item-cls} {
  height: 100%;
}

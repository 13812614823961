@import '../../style/mixins';
@import '../../style/themes/default';

@listPrefixCls: am-list;
@radioWarpPrefixCls: am-radio;
@radioInnerPrefixCls: ~"@{radioWarpPrefixCls}-inner";

.@{radioWarpPrefixCls} {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: @icon-size-xxs;
  height: @icon-size-xxs;

  &-inner {
    position: absolute;
    right: 0;
    width: @icon-size-xxs;
    height: @icon-size-xxs;
    box-sizing: border-box;
    transform: rotate(0deg);

    &:after {
      position: absolute;
      display: none;
      top: -2.5 * @hd;
      right: 5 * @hd;
      z-index: 999;
      width: 7 * @hd;
      height: 14 * @hd;
      border-style: solid;
      border-width: 0 1.5 * @hd 1.5 * @hd 0;
      content: '\0020';
      transform: rotate(45deg);
    }
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0 none;
    appearance: none;
  }

  &.@{radioWarpPrefixCls}-checked {
    .@{radioInnerPrefixCls} {
      border-width: 0;

      &:after {
        display: block;
        border-color: @brand-primary;
      }
    }
  }

  &.@{radioWarpPrefixCls}-disabled {
    &.@{radioWarpPrefixCls}-checked {
      .@{radioInnerPrefixCls} {
        &:after {
          display: block;
          border-color: @color-text-disabled;
        }
      }
    }
  }
}

.@{listPrefixCls} {
  & &-item {
    &.@{radioWarpPrefixCls}-item {
      .@{listPrefixCls}-line {
        .@{listPrefixCls}-extra {
          flex: 0;

          .@{radioWarpPrefixCls} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: @list-item-height;
            overflow: visible;

            &-inner {
              right: @v-spacing-lg;
              top: @h-spacing-lg;
            }
          }
        }
      }

      &.@{radioWarpPrefixCls}-item-disabled {
        .@{listPrefixCls}-content {
          color: @color-text-disabled;
        }
      }
    }
  }
}

@import '../../style/themes/default';
@import '../../style/mixins';

.am-icon {
  fill: currentColor;
  background-size: cover;
  width: @icon-size-md;
  height: @icon-size-md;

  &-xxs {
    width: @icon-size-xxs;
    height: @icon-size-xxs;
  }

  &-xs {
    width: @icon-size-xs;
    height: @icon-size-xs;
  }

  &-sm {
    width: @icon-size-sm;
    height: @icon-size-sm;
  }

  &-md {
    width: @icon-size-md;
    height: @icon-size-md;
  }

  &-lg {
    width: @icon-size-lg;
    height: @icon-size-lg;
  }

  &-loading {
    animation: cirle-anim 1s linear infinite;
  }

  @keyframes cirle-anim {
    100% {
      transform: rotate(360deg);
    }
  }
}

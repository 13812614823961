@import '../../style/mixins';
@import '../../style/themes/default';
@import './custom-keyboard';

@listPrefixCls: am-list;
@inputPrefixCls: am-input;

.@{listPrefixCls}-item {
  &.@{inputPrefixCls}-item {
    height: @list-item-height;
    padding-left: @h-spacing-lg;
  }

  &:not(:last-child) {
    .@{listPrefixCls}-line {
      .hairline('bottom');
    }
  }

  .@{inputPrefixCls}-label {
    color: @color-text-base;
    font-size: @font-size-heading;
    margin-left: 0;
    margin-right: @h-spacing-sm;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    padding: 2 * @hd 0;

    &.@{inputPrefixCls}-label-2 {
      width: 2 * @input-label-width;
    }

    &.@{inputPrefixCls}-label-3 {
      width: 3 * @input-label-width;
    }

    &.@{inputPrefixCls}-label-4 {
      width: 4 * @input-label-width;
    }

    &.@{inputPrefixCls}-label-5 {
      width: 5 * @input-label-width;
    }

    &.@{inputPrefixCls}-label-6 {
      width: 6 * @input-label-width;
    }

    &.@{inputPrefixCls}-label-7 {
      width: 7 * @input-label-width;
    }
  }

  .@{inputPrefixCls}-control {
    font-size: @input-font-size;
    flex: 1;

    input {
      color: @color-text-base;
      font-size: @font-size-heading;
      appearance: none;
      width: 100%;
      padding: 2 * @hd 0;
      border: 0;
      background-color: transparent;
      line-height: @line-height-base;
      box-sizing: border-box;

      &::placeholder {
        color: @color-text-placeholder;
        line-height: 1.2;
      }

      &:disabled {
        color: @color-text-disabled;
        background-color: #fff;
      }
    }
  }

  .@{inputPrefixCls}-clear {
    display: none;
    width: @icon-size-sm;
    height: @icon-size-sm;
    border-radius: @radius-circle;
    overflow: hidden;
    font-style: normal;
    color: @fill-base;
    background-color: @input-color-icon;
    background-repeat: no-repeat;
    .encoded-svg-background('input_item_delete');

    background-size: @icon-size-sm auto;
    background-position: 2 * @hd 2 * @hd;

    &-active {
      background-color: @input-color-icon-tap;
    }
  }

  &.@{inputPrefixCls}-focus {
    .@{inputPrefixCls}-clear {
      display: block;
    }
  }

  .@{inputPrefixCls}-extra {
    flex: initial;
    min-width: 0;
    max-height: @icon-size-sm;
    overflow: hidden;
    padding-right: 0;
    line-height: @line-height-base;
    color: @color-text-caption;
    font-size: @font-size-subhead;
    margin-left: @h-spacing-sm;
  }

  &.@{inputPrefixCls}-error {
    .@{inputPrefixCls}-control {
      input {
        color: #f50;
      }
    }

    .@{inputPrefixCls}-error-extra {
      height: @icon-size-sm;
      width: @icon-size-sm;
      margin-left: @v-spacing-sm;
      .encoded-svg-background('error');

      background-size: @icon-size-sm auto;
    }
  }

  &.@{inputPrefixCls}-disabled {
    .@{inputPrefixCls}-label {
      color: @color-text-disabled;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@import '../../style/mixins';
@import '../../style/themes/default';

.am-carousel {
  position: relative;

  &-wrap {
    font-size: 18 * @hd;
    color: @color-text-base;
    background: none;
    text-align: center;
    zoom: 1;
    width: 100%;

    &-dot {
      display: inline-block;
      zoom: 1;

      > span {
        display: block;
        width: 8 * @hd;
        height: 8 * @hd;
        margin: 0 3 * @hd;
        border-radius: @radius-circle;
        background: @color-icon-base;
      }

      &-active {
        > span {
          background: #888;
        }
      }
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@toastPrefixCls: am-toast;

.@{toastPrefixCls} {
  position: fixed;
  width: 100%;
  z-index: @toast-zindex;
  font-size: @font-size-base;
  text-align: center;

  > span {
    max-width: 50%;
  }

  &&-mask {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    transform: translateZ(1px);
  }

  &&-nomask {
    position: fixed;
    max-width: 50%;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translateZ(1px);
    .@{toastPrefixCls}-notice {
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &-notice-content {
    .@{toastPrefixCls}-text {
      min-width: 60 * @hd;
      border-radius: @radius-sm;
      color: @color-text-base-inverse;
      background-color: @toast-fill;
      line-height: @line-height-paragraph;
      padding: @v-spacing-md @h-spacing-lg;

      &.@{toastPrefixCls}-text-icon {
        border-radius: @radius-md;
        padding: @v-spacing-lg @h-spacing-lg;

        .@{toastPrefixCls}-text-info {
          margin-top: @v-spacing-sm;
        }
      }
    }
  }
}

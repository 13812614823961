@popover-arrow-width: 7 * @hd;

// Base class
.@{popoverPrefixCls} {
  position: absolute;
  z-index: @popover-zindex;

  &-hidden {
    display: none;
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: @fill-mask;
    height: 100%;
    z-index: @popup-zindex;

    &-hidden {
      display: none;
    }
  }
}

// Arrows
.@{popoverPrefixCls}-arrow {
  position: absolute;
  width: @popover-arrow-width;
  height: @popover-arrow-width;
  border-radius: 1PX;
  background-color: @fill-base;
  transform: rotate(45deg);
  z-index: 0;
  box-shadow: 0 0 2 * @hd rgba(0, 0, 0, 0.21);
}

.@{popoverPrefixCls} {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    transform: rotate(225deg);
    bottom: -@popover-arrow-width/2;
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: @h-spacing-md;
  }

  &-placement-topRight &-arrow {
    right: @h-spacing-md;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    transform: rotate(-45deg);
    left: -@popover-arrow-width/2;
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: @h-spacing-md;
  }

  &-placement-rightBottom &-arrow {
    bottom: @h-spacing-md;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    transform: rotate(135deg);
    right: -@popover-arrow-width/2;
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: @h-spacing-md;
  }

  &-placement-leftBottom &-arrow {
    bottom: @h-spacing-md;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: -@popover-arrow-width/2;
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: @h-spacing-md;
  }

  &-placement-bottomRight &-arrow {
    right: @h-spacing-md;
  }
}

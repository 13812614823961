@import '../../style/themes/default';
@pull-to-refresh: am-pull-to-refresh;

.@{pull-to-refresh} {
  &-content {
    &-wrapper {
      overflow: hidden;
    }
    transform-origin: left top 0;
  }

  &-transition {
    transition: transform 0.3s;
  }

  &-indicator {
    color: grey;
    text-align: center;
    height: 25 * @hd;
  }

  &-down .@{pull-to-refresh}-indicator {
    margin-top: -25 * @hd;
  }

  &-up .@{pull-to-refresh}-indicator {
    margin-bottom: -25 * @hd;
  }
}

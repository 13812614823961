@import '../../style/mixins';
@import '../../style/themes/default';
@ActionSheetPrefixCls: am-action-sheet;

@import './base';

.@{ActionSheetPrefixCls} {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: @fill-base;
  padding-bottom: env(safe-area-inset-bottom);

  &&-share {
    background-color: #f2f2f2;
  }

  &-title,
  &-message {
    margin: @h-spacing-lg auto;
    padding: 0 @h-spacing-lg;
    text-align: center;
  }

  &-title {
    font-size: @font-size-heading;
  }

  &-message {
    color: @color-text-caption;
    font-size: @font-size-base;
  }

  &-button-list {
    text-align: center;
    color: @color-text-base;

    &-item {
      font-size: @actionsheet-item-font-size;
      padding: 0 8 * @hd;
      margin: 0;
      position: relative;
      height: @actionsheet-item-height;
      line-height: @actionsheet-item-height;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      .hairline('top');
      &&-active {
        background-color: @fill-tap;
      }
    }
    &-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      .am-badge {
        margin-left: 8 * @hd;
        flex-shrink: 0;
      }
    }
    &-item-content {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .@{ActionSheetPrefixCls}-cancel-button {
      padding-top: @v-spacing-sm;
      position: relative;

      &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: @v-spacing-sm;
        background-color: #e7e7ed;
        .hairline('top');
        .hairline('bottom');
      }
    }

    .@{ActionSheetPrefixCls}-destructive-button {
      color: @brand-error;
    }
  }

  &-share {
    &-list {
      display: flex;
      position: relative;
      .hairline('top');

      padding: @v-spacing-xl 0 @v-spacing-xl @h-spacing-lg;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &-item {
        flex: none;
        margin: 0 12 * @hd 0 0;

        &-icon {
          margin-bottom: @v-spacing-md;
          width: 60 * @hd;
          height: 60 * @hd;
          background-color: @fill-base;
          border-radius: @radius-sm;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-title {
          color: @color-text-caption;
          font-size: @font-size-icontext;
          text-align: center;
        }
      }
    }

    &-cancel-button {
      height: @actionsheet-item-height;
      line-height: @actionsheet-item-height;
      text-align: center;
      background-color: @fill-base;
      color: @color-text-base;
      font-size: @button-font-size;
      position: relative;
      .hairline('top');
      box-sizing: border-box;

      &&-active {
        background-color: @fill-tap;
      }
    }
  }
}

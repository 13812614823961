@import '../../style/mixins';
@import '../../style/themes/default';

@searchPrefixCls: am-search;

/* 默认搜索bar */
.@{searchPrefixCls} {
  position: relative;
  display: flex;
  align-items: center;
  height: @search-bar-height;
  padding: 0 @h-spacing-md;
  overflow: hidden;
  background-color: @search-bar-fill;

  &-input {
    flex: 1;
    position: relative;
    width: 100%;
    height: @search-bar-input-height;
    overflow: hidden;
    background-color: @fill-base;
    background-clip: padding-box;
    border-radius: @radius-sm;

    .@{searchPrefixCls}-synthetic-ph,
    input[type="search"] {
      position: absolute;
      top: 0;
      left: 0;
    }

    .@{searchPrefixCls}-synthetic-ph {
      box-sizing: content-box;
      z-index: 1;
      height: @search-bar-input-height;
      line-height: @search-bar-input-height;
      width: 100%;
      transition: width .3s;
      display: block;
      text-align: center;

      &-icon {
        display: inline-block;
        margin-right: @h-spacing-sm;
        width: @icon-size-xxs;
        height: @icon-size-xxs;
        overflow: hidden;
        vertical-align: -2.5 * @hd;
        background-repeat: no-repeat;
        background-size: @icon-size-xxs auto;
        .encoded-svg-background('search_bar_search');
      }

      &-placeholder {
        color: @color-text-placeholder;
        font-size: @search-bar-font-size;
      }
    }

    input[type="search"] {
      z-index: 2;
      opacity: 0;
      width: 100%;
      text-align: left;
      display: block;
      color: @color-text-base;
      height: @search-bar-input-height;
      font-size: @search-bar-font-size;
      background-color: transparent;
      border: 0;

      &::placeholder {
        background: none;
        text-align: left;
        // color: @color-text-placeholder;
        color: transparent;
        // font-size: @input-font-size;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }

    .@{searchPrefixCls}-clear {
      box-sizing: content-box;
      position: absolute;
      display: none;
      z-index: 3;
      width: @icon-size-xxs;
      height: @icon-size-xxs;
      padding: (@search-bar-input-height - @icon-size-xxs) / 2;
      border-radius: @radius-circle;
      top: 0;
      right: 0;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: @icon-size-xxs @icon-size-xxs;
      transition: all .3s;
      .encoded-svg-background('search_bar_clear');

      &-active {
        .encoded-svg-background('search_bar_clear_active');
      }

      &-show {
        display: block;
      }
    }
  }

  &-cancel {
    flex: none;
    opacity: 0;
    padding-left: @h-spacing-md;
    height: @search-bar-height;
    line-height: @search-bar-height;
    font-size: @link-button-font-size;
    color: @brand-primary;
    text-align: right;

    &-anim {
      transition: margin-right .3s, opacity .3s;
      transition-delay: .1s;
    }

    &-show {
      opacity: 1;
    }
  }

  &.@{searchPrefixCls}-start {
    .@{searchPrefixCls}-input {
      input[type="search"] {
        opacity: 1;
        padding: 0 @search-bar-input-height 0 @h-spacing-lg + @icon-size-xxs + @h-spacing-sm;

        &::placeholder {
          // display: none;
          color: transparent;
        }
      }

      .@{searchPrefixCls}-synthetic-ph {
        padding-left: @h-spacing-lg;
        width: auto;
      }
    }
  }
}

@import 'variables';

.@{stepsPrefixCls}-item-custom {
  .@{stepsPrefixCls}-item-icon {
    background: none;
    border: 0;
    width: auto;
    height: auto;
    > .@{stepsPrefixCls}-icon {
      font-size: @defaultIconSize;
      top: 1 * @hd;
      width: @defaultIconSize;
      height: @defaultIconSize;
    }
  }
  &.@{stepsPrefixCls}-item-process {
    .@{stepsPrefixCls}-item-icon > .@{stepsPrefixCls}-icon {
      color: @brand-primary;
    }
  }
}

@import '../../style/themes/default';
@import '../../style/mixins';

@segment-prefix-cls: am-segment;

.@{segment-prefix-cls} {
  display: flex;
  border-radius: @radius-md;
  overflow: hidden;
  min-height: @segmented-control-height;
  opacity: 1;

  &&-disabled {
    opacity: 0.5;
  }

  &-item {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: @segmented-control-color;
    font-size: @font-size-base;
    line-height: @line-height-base;
    transition: background .2s;
    position: relative;
    border: @border-width-md solid @segmented-control-color;
    width: 100%;
    box-sizing: border-box;
    border-left-width: 0;

    &-tintcolor {
      border-color: @segmented-control-color;
    }

    &:first-child {
      border-left-width: @border-width-md;
      border-radius: @radius-md 0 0 @radius-md;
    }

    &:last-child {
      border-radius: 0 @radius-md @radius-md 0;
    }

    &-selected {
      background: @segmented-control-color;
      color: @color-text-base-inverse;
    }

    &-active {
      .@{segment-prefix-cls}-item-inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.1;
        transition: background .2s;
        background-color: @segmented-control-color;
      }
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@import 'variables';

.@{stepsPrefixCls} {
  font-size: 0;
  width: 100%;
  line-height: @line-height-paragraph;
  display: flex;

  &,
  * {
    box-sizing: border-box;
  }
}

.@{stepsPrefixCls}-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  overflow: hidden;

  &:last-child {
    flex: none;
  }

  &:last-child &-tail,
  &:last-child &-title:after {
    display: none;
  }

  &-icon,
  &-content {
    display: inline-block;
    vertical-align: top;
  }

  &-icon {
    border: 1 * @hd solid @color-text-placeholder;
    width: @defaultIconSize;
    height: @defaultIconSize;
    line-height: @defaultIconSize;
    border-radius: @defaultIconSize;
    text-align: center;
    font-size: @font-size-base;
    margin-right: @h-spacing-md;
    transition: background-color .3s, border-color .3s;

    > .@{stepsPrefixCls}-icon {
      line-height: 1;
      top: -1 * @hd;
      color: @brand-primary;
      position: relative;

      .@{iconPrefixCls}-icon {
        font-size: @font-size-caption-sm;
        position: relative;
        float: left;
      }
    }
  }

  &-tail {
    position: absolute;
    left: 0;
    width: 100%;
    top: 12 * @hd;
    padding: 0 10 * @hd;
    &:after {
      content: '';
      display: inline-block;
      background: @border-color-base;
      height: 1 * @hd;
      border-radius: 1 * @hd;
      width: 100%;
      transition: background .3s;
      position: relative;
      left: -2 * @hd;
    }
  }
  &-content {
    margin-top: 3 * @hd;
  }
  &-title {
    font-size: @font-size-caption;
    margin-bottom: 4 * @hd;
    color: @color-text-base;
    font-weight: bold;
    display: inline-block;
    padding-right: 10 * @hd;
    position: relative;
  }
  &-description {
    font-size: @font-size-subhead;
    color: @color-text-placeholder;
  }
  .step-item-status(wait);
  .step-item-status(process);
  &-process &-icon {
    background: @brand-primary;
    > .@{stepsPrefixCls}-icon {
      color: @color-text-base-inverse;
    }
  }
  .step-item-status(finish);
  .step-item-status(error);

  &.@{stepsPrefixCls}-next-error .@{stepsPrefixCls}-item-title:after {
    background: @brand-error;
  }

  &.@{errorTailCls} .@{stepsPrefixCls}-item-tail:after {
    background-color: @brand-error;
  }
}

.@{stepsPrefixCls}-horizontal:not(.@{stepsPrefixCls}-label-vertical) {
  .@{stepsPrefixCls}-item {
    margin-right: 10 * @hd;
    &:last-child {
      margin-right: 0;
    }
    &-tail {
      display: none;
    }
    &-description {
      max-width: @stepDescriptionMaxWidth;
    }
  }
}

.step-item-status(@status) {
  @icon-color: "@{status}-icon-color";
  @title-color: "@{status}-title-color";
  @description-color: "@{status}-description-color";
  @tail-color: "@{status}-tail-color";
  &-@{status} &-icon {
    border-color: @@icon-color;
    background-color: @fill-base;
    > .@{stepsPrefixCls}-icon {
      color: @@icon-color;
      .@{stepsPrefixCls}-icon-dot {
        background: @@icon-color;
      }
    }
  }
  &-@{status} &-title {
    color: @@title-color;
    &:after {
      background-color: @@tail-color;
    }
  }
  &-@{status} &-description {
    color: @@description-color;
  }
  &-@{status} &-tail:after {
    background-color: @@tail-color;
  }
}

@import 'custom-icon';
@import 'small';
@import 'vertical';
@import 'label-placement';

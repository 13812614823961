@import '../../style/mixins';
@import '../../style/themes/default';

@cardPrefixCls: am-card;

.@{cardPrefixCls} {
  &:not(.@{cardPrefixCls}-full) {
    .hairline('all', @border-color-base, @radius-md);
  }

  min-height: 96 * @hd;
  padding-bottom: @v-spacing-sm;
  display: flex;
  flex-direction: column;
  background-color: @fill-base;

  &&-full {
    position: relative;
    .hairline('top');
    .hairline('bottom');
  }

  &-header {
    display: flex;
    align-items: center;
    font-size: @font-size-heading;
    padding: @v-spacing-md @h-spacing-lg;

    &-content {
      flex: 1;
      text-align: left;
      color: @color-text-base;
      display: flex;
      align-items: center;

      img {
        margin-right: @h-spacing-sm;
      }
    }

    &-extra {
      flex: 1;
      text-align: right;
      font-size: @font-size-heading;
      color: @color-text-caption;
    }
  }

  &-body {
    position: relative;
    .hairline('top');
    padding: @v-spacing-lg @h-spacing-lg @v-spacing-sm;
    font-size: @font-size-subhead;
    color: @color-text-paragraph;
    min-height: 40 * @hd;
    flex: 1;
  }

  &-footer {
    font-size: @font-size-base;
    color: @color-text-caption;
    padding: 0 @h-spacing-lg;
    display: flex;

    &-content {
      flex: 1;
    }

    &-extra {
      flex: 1;
      text-align: right;
    }
  }
}

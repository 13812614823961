.@{ActionSheetPrefixCls} {
  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @action-sheet-zindex;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: @fill-mask;
    height: 100%;
    z-index: @action-sheet-zindex;

    &-hidden {
      display: none;
    }
  }
  // 隐藏掉 dialog 默认的 x 按钮
  &-close {
    display: none;
  }
}

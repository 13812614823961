@import 'variables';

.@{stepsPrefixCls}-vertical {
  display: block;
  .@{stepsPrefixCls}-item {
    display: block;
    overflow: visible;
    &-icon {
      float: left;
      &-inner {
        margin-right: 16 * @hd;
      }
    }
    &-content {
      min-height: 48 * @hd;
      overflow: hidden;
      display: block;
    }
    &-title {
      line-height: 26 * @hd;
      &:after {
        display: none;
      }
    }
    &-description {
      padding-bottom: 12 * @hd;
    }
    &-tail {
      position: absolute;
      left: 13 * @hd;
      top: 0;
      height: 100%;
      width: 1 * @hd;
      padding: 30 * @hd 0 4 * @hd 0;
      &:after {
        height: 100%;
        width: 1 * @hd;
      }
    }
  }

  &.@{stepsPrefixCls}-small {
    .@{stepsPrefixCls}-item-tail {
      position: absolute;
      left: 9 * @hd;
      top: 0;
      padding: 22 * @hd 0 4 * @hd 0;
    }
    .@{stepsPrefixCls}-item-title {
      line-height: 18 * @hd;
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@modalPrefixClass: am-modal;

@import './Dialog.less';

.@{modalPrefixClass} {
  .button-common() {
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: @color-link;
    font-size: @modal-button-font-size;
    height: @modal-button-height;
    line-height: @modal-button-height;
    display: block;
    .ellipsis();
  }

  &-button-group-h {
    position: relative;
    .hairline('top');
    display: flex;

    .@{modalPrefixClass}-button {
      -webkit-touch-callout: none;
      flex: 1;
      .button-common();

      &:first-child {
        color: @color-text-base;
      }
      &:last-child {
        position: relative;
        .hairline('left');
      }
    }
  }

  &-button-group-v {
    .@{modalPrefixClass}-button {
      -webkit-touch-callout: none;
      display: block;
      position: relative;
      .hairline('top');
      .button-common();
    }
  }

  &-button-active {
    background-color: @fill-tap;
  }

  &-input-container {
    margin-top: @v-spacing-md;
    .hairline('all', @border-color-base, @radius-sm);
  }
  &-input {
    height: 36 * @hd;
    line-height: @line-height-base;

    &:nth-child(2) {
      position: relative;
      .hairline('top');
    }
    input {
      position: relative;
      border: 0;
      width: 98%;
      height: 34 * @hd;
      top: 1PX;
      box-sizing: border-box;  // maybe not need it ?

      margin: 0;
      &::placeholder {
        font-size: @font-size-base;
        color: #ccc;
        padding-left: @h-spacing-md;
      }
    }
  }

  &&-transparent.@{modalPrefixClass}-android {
    .@{modalPrefixClass}-content {
      border-radius: 0;

      .@{modalPrefixClass}-header {
        padding: @v-spacing-md 24 * @hd 12 * @hd;

        .@{modalPrefixClass}-title {
          text-align: left;
          font-size: 21 * @hd;
          color: @color-text-base;
        }
      }

      .@{modalPrefixClass}-body {
        color: @color-text-base;
        text-align: left;
        padding: 0 24 * @hd @v-spacing-lg;

        .@{modalPrefixClass}-input-container {
          .hairline-remove('all');
          .hairline('bottom');
          .@{modalPrefixClass}-input {
            &:first-child {
              .hairline-remove('top');
            }
          }
        }
      }

      .@{modalPrefixClass}-footer {
        padding-bottom: 2 * @v-spacing-sm;

        .@{modalPrefixClass}-button-group-h {
          overflow: hidden;
          .hairline-remove('top');
          justify-content: flex-end;
          padding: 0 2 * @v-spacing-sm;

          .@{modalPrefixClass}-button {
            flex: initial;
            margin-left: 3 * @hd;
            padding: 0 @h-spacing-lg;
            height: 48 * @hd;
            box-sizing: border-box;

            &:first-child {
              color: #777;
            }
            &:last-child {
              .hairline-remove('left');
            }
          }
        }

        .@{modalPrefixClass}-button-group-v.@{modalPrefixClass}-button-group-normal {
          display: flex;
          justify-content: flex-end;
          overflow: hidden;
          padding: 0 2 * @v-spacing-sm;

          .@{modalPrefixClass}-button {
            .hairline-remove('top');
            padding: 0 @h-spacing-lg;
            margin-left: 3 * @hd;
            height: 48 * @hd;
            box-sizing: border-box;
          }
        }
        .@{modalPrefixClass}-button-group-operation {
          .@{modalPrefixClass}-button {
            text-align: start;
            padding-left: 15 * @hd;
          }
        }
      }
    }
  }
  &&-operation {
    .@{modalPrefixClass}-content {
      border-radius: @radius-lg;
      height: auto;
      padding-top: 0;

      .@{modalPrefixClass}-body {
        padding: 0!important;
      }

      .@{modalPrefixClass}-button {
        color: @color-text-base;
        text-align: left;
        padding-left: @h-spacing-lg;
      }
    }
  }

  &-alert-content,
  &-propmt-content {
    zoom: 1;
    overflow: hidden;
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@listPrefixCls: am-list;
@textareaPrefixCls: am-textarea;

.@{listPrefixCls} .@{listPrefixCls}-item {
  &.@{textareaPrefixCls}-item {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    min-height: @list-item-height;
    padding-left: @h-spacing-lg;
    padding-right: @h-spacing-lg;
    .hairline('bottom');

    &.@{textareaPrefixCls}-item-single-line {
      align-items: center;

      .@{textareaPrefixCls}-label {
        align-self: center;
      }

      .@{textareaPrefixCls}-control {
        padding-top: 0;
        padding-bottom: 0;

        textarea {
          line-height: @line-height-paragraph * @font-size-heading;
        }
      }

      .@{textareaPrefixCls}-clear {
        margin-top: 0;
      }

      &.@{textareaPrefixCls}-error {
        .@{textareaPrefixCls}-error-extra {
          margin-top: 0;
        }
      }
    }
  }
}

.@{textareaPrefixCls}-label {
  align-self: flex-start;
  color: @color-text-base;
  text-align: left;
  min-height: @list-item-height;
  font-size: @font-size-heading;
  line-height: @list-item-height;
  margin-left: 0;
  margin-right: @h-spacing-sm;
  white-space: nowrap;
  overflow: hidden;

  &.@{textareaPrefixCls}-label-2 {
    width: 2 * @input-label-width;
  }

  &.@{textareaPrefixCls}-label-3 {
    width: 3 * @input-label-width;
  }

  &.@{textareaPrefixCls}-label-4 {
    width: 4 * @input-label-width;
  }

  &.@{textareaPrefixCls}-label-5 {
    width: 5 * @input-label-width;
  }

  &.@{textareaPrefixCls}-label-6 {
    width: 6 * @input-label-width;
  }

  &.@{textareaPrefixCls}-label-7 {
    width: 7 * @input-label-width;
  }
}

.@{textareaPrefixCls}-control {
  flex: 1;
  padding-top: 10 * @hd;
  padding-bottom: 9 * @hd;

  textarea {
    color: @color-text-base;
    font-size: @font-size-heading;
    line-height: @line-height-paragraph * @font-size-heading;
    appearance: none;
    width: 100%;
    padding: 0;
    border: 0;
    background-color: transparent;
    overflow: visible;
    display: block;
    resize: none;
    word-break: break-word;
    word-wrap: break-word;

    &::placeholder {
      color: @color-text-placeholder;
    }

    &:disabled {
      color: @color-text-disabled;
      background-color: #fff;
    }
  }
}

.@{textareaPrefixCls}-clear {
  display: none;
  width: @icon-size-sm;
  height: @icon-size-sm;
  margin-top: 12 * @hd;
  border-radius: @radius-circle;
  overflow: hidden;
  font-style: normal;
  color: #fff;
  background-color: @input-color-icon;
  background-repeat: no-repeat;
  background-size: @icon-size-sm auto;
  .encoded-svg-background('textarea_item_delete');

  &-active {
    background-color: @input-color-icon-tap;
  }
}

&.@{textareaPrefixCls}-focus {
  .@{textareaPrefixCls}-clear {
    display: block;
  }
}

.@{textareaPrefixCls}-has-count {
  padding-bottom: 14px;
}

.@{textareaPrefixCls}-count {
  position: absolute;
  bottom: @v-spacing-sm;
  right: @h-spacing-sm;
  color: @color-text-placeholder;
  font-size: @font-size-base;

  span {
    color: @color-text-base;
  }
}

&.@{textareaPrefixCls}-error {
  .@{textareaPrefixCls}-control {
    textarea {
      color: #f50;
    }
  }

  .@{textareaPrefixCls}-error-extra {
    margin-top: 12 * @hd;
    width: @icon-size-sm;
    height: @icon-size-sm;
    margin-left: @h-spacing-md;
    background-size: @icon-size-sm @icon-size-sm;
    .encoded-svg-background('error');
  }
}

&.@{textareaPrefixCls}-disabled {
  .@{textareaPrefixCls}-label {
    color: @color-text-disabled;
  }
}

.@{listPrefixCls} {
  &-body {
    .@{listPrefixCls}-item {
      &:last-child {
        .hairline-remove('bottom');
      }
    }
  }
}

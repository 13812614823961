@import '../../style/mixins';
@import '../../style/themes/default';

@flexPrefixCls: am-flexbox;
@gridPrefixCls: am-grid;

/* flexbox */
.@{gridPrefixCls} {
  .@{flexPrefixCls} {
    background: @fill-base;

    .@{flexPrefixCls}-item {
      margin-left: 0;

      &.@{gridPrefixCls}-item {
        position: relative;
      }

      &.@{gridPrefixCls}-item-active {
        .@{gridPrefixCls}-item-content {
          background-color: @fill-tap;
        }
      }

      .@{gridPrefixCls}-item-content {
        text-align: center;
        width: 100%;
        height: 100%;
        padding: @v-spacing-lg 0;

        .@{gridPrefixCls}-item-inner-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .@{gridPrefixCls}-icon {
            max-width: 100%;
          }

          .@{gridPrefixCls}-text {
            margin-top: @v-spacing-md;
            font-size: @font-size-caption-sm;
            color: @color-text-base;
            text-align: center;
          }

          &.column-num-3 {
            .@{gridPrefixCls}-text {
              font-size: @font-size-caption;
            }
          }

          &.column-num-2 {
            .@{gridPrefixCls}-text {
              margin-top: @v-spacing-lg;
              font-size: 18 * @hd;
            }
          }
        }
      }
    }
  }

  &.@{gridPrefixCls}-line {
    position: relative;
    &:not(.@{gridPrefixCls}-carousel) {
      .hairline('top');
      .hairline('right');
    }

    .@{flexPrefixCls} {
      position: relative;
      .hairline('bottom');

      .@{flexPrefixCls}-item {
        position: relative;
        &:first-child {
          .hairline('left');
        }
        &:not(:last-child) {
          .hairline('right');
        }
      }
    }

    &.@{gridPrefixCls}-carousel {
      .@{gridPrefixCls}-carousel-page {
        .hairline('top');
        .hairline('right');
      }
    }
  }

  .am-carousel {
    .am-carousel-wrap-dot > span {
      background: #dcdee3;
    }

    .am-carousel-wrap-dot-active > span {
      background: #0ae;
    }
  }

  &.@{gridPrefixCls}-square {
    .@{gridPrefixCls}-item {
      &:before {
        display: block;
        content: ' ';
        padding-bottom: 100%;
      }
      .@{gridPrefixCls}-item-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .@{gridPrefixCls}-item-inner-content {
        height: 100%;
        .@{gridPrefixCls}-icon {
          margin-top: @v-spacing-md;
          width: 28%!important;
        }
      }
    }
  }
}

@import '../../style/mixins';
@import '../../style/themes/default';

@menuPrefixCls: am-menu;
@subMenuPrefixCls: am-sub-menu;
@MenuSelectContanerPrefixCls: am-menu-select-container;
@multiSelectMenuBtnsCls: am-multi-select-btns;
@flexPrefixCls: am-flexbox;
@listPrefixCls: am-list;
@checkboxPrefixCls: am-checkbox;
@firstMenuColor: @fill-grey;

.@{menuPrefixCls} {
  background-color: @fill-body;

  .@{MenuSelectContanerPrefixCls} {
    flex-grow: 2;

    .@{MenuSelectContanerPrefixCls}-submenu {
      align-self: stretch;
    }
  }

  .@{multiSelectMenuBtnsCls} {
    height: @menu-multi-select-btns-height;
    width: 100%;

    .@{multiSelectMenuBtnsCls}-btn {
      width: 50%;
      height: 100%;
      border-radius: 0;

      .hairline('all', @border-color-base, 0);
    }
  }

  .@{flexPrefixCls} {
    .@{flexPrefixCls}-item {
      margin-left: 0;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;

      .@{listPrefixCls} {
        padding: 0;
      }

      .@{listPrefixCls} {
        .@{listPrefixCls}-item {
          .@{listPrefixCls}-line {
            .@{listPrefixCls}-content {
              font-size: @link-button-font-size;
            }
            .@{listPrefixCls}-extra {
              .@{checkboxPrefixCls}-wrapper {
                .@{checkboxPrefixCls} {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  overflow: visible;

                  .@{checkboxPrefixCls}-inner {
                    top: 12px;
                    right: 15px;
                  }
                }
              }
            }
          }
        }
      }

      &:first-child {
        background-color: @firstMenuColor;

        .@{listPrefixCls} {
          .@{listPrefixCls}-body {
            background-color: @firstMenuColor;
            .hairline-remove('bottom');
          }

          .@{listPrefixCls}-item {
            background-color: @firstMenuColor;

            .@{listPrefixCls}-line {
              .hairline-remove('bottom');

              .@{listPrefixCls}-content {
                color: @color-text-base;
              }
            }

            &:last-child {
              .hairline-remove('bottom');
            }

            &.@{menuPrefixCls}-selected {
              background-color: @fill-base;
            }
          }
        }
      }

      &:last-child {
        background-color: @fill-base;

        .@{listPrefixCls} {
          .@{listPrefixCls}-item {
            background-color: @fill-base;
            .hairline-remove('bottom');

            .@{listPrefixCls}-line {
              .@{listPrefixCls}-extra {
                flex: 0;
              }
            }

            &.@{subMenuPrefixCls}-item-selected {
              .@{listPrefixCls}-line {
                .@{listPrefixCls}-content {
                  color: @color-link;
                }
              }
            }

            &.@{subMenuPrefixCls}-item-disabled {
              .@{listPrefixCls}-line {
                .@{listPrefixCls}-content {
                  color: @color-text-disabled;
                }
              }
            }
          }
        }
      }

      &:only-child {
        .@{listPrefixCls} {
          .@{listPrefixCls}-item {
            .@{listPrefixCls}-line {
              .hairline('bottom');
            }

            &:last-child {
              .hairline('bottom');

              .@{listPrefixCls}-line {
                .hairline-remove('bottom');
              }
            }
          }
        }
      }
    }
  }
}

@import './themes/default';
@import './mixins';
@import './anim';

// 为 body 背景单独加一个 less 变量, 使使用者可以修改
@am-body-background: @fill-body;

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: @am-body-background;
  font-size: @font-size-base;
}

*[contenteditable] {
  -webkit-user-select: auto !important;
}

*:focus {
  outline: none;
}

a {
  background: transparent;
  text-decoration: none;
  outline: none;
}

.@{pickerColPrefixCls} {
  display: block;
  position: relative;
  height: 7 * @picker-item-height;
  overflow: hidden;
  width: 100%;

  &-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    padding: 3 * @picker-item-height 0;
  }

  &-item {
    touch-action: manipulation;
    text-align: center;
    font-size: @font-size-caption;
    height: @picker-item-height;
    line-height: @picker-item-height;
    color: @color-text-base;
    white-space: nowrap;
    text-overflow: ellipsis;

    &-selected {
      font-size: @font-size-heading;
    }
  }

  &-mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    z-index: 3;
    background-image:
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)),
      -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
    background-image:
      linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)),
      linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
    background-position: top, bottom;
    background-size: 100% 3 * @picker-item-height;
    background-repeat: no-repeat;
  }

  &-indicator {
    box-sizing: border-box;
    width: 100%;
    height: @picker-item-height;
    position: absolute;
    left: 0;
    top: 3 * @picker-item-height;
    z-index: 3;
    .hairline('top');
    .hairline('bottom');
  }
}

@import '../../style/mixins';

@flexPrefixCls: am-flexbox;

/* flexbox */
.@{flexPrefixCls} {
  text-align: left;
  overflow: hidden;
  display: flex;
  align-items: center;

  &&-dir-row {
    flex-direction: row;
  }

  &&-dir-row-reverse {
    flex-direction: row-reverse;
  }

  &&-dir-column {
    flex-direction: column;
    .@{flexPrefixCls}-item {
      margin-left: 0;
    }
  }

  &&-dir-column-reverse {
    flex-direction: column-reverse;
    .@{flexPrefixCls}-item {
      margin-left: 0;
    }
  }

  &&-nowrap {
    flex-wrap: nowrap;
  }

  &&-wrap {
    flex-wrap: wrap;
  }

  &&-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &&-justify-start {
    justify-content: flex-start;
  }

  &&-justify-end {
    justify-content: flex-end;
  }

  &&-justify-center {
    justify-content: center;
  }

  &&-justify-between {
    justify-content: space-between;
  }

  &&-justify-around {
    justify-content: space-around;
  }

  &&-align-start {
    align-items: flex-start;
  }

  &&-align-end {
    align-items: flex-end;
  }

  &&-align-center {
    align-items: center;
  }

  &&-align-stretch {
    align-items: stretch;
  }

  &&-align-baseline {
    align-items: baseline;
  }

  &&-align-content-start {
    align-content: flex-start;
  }

  &&-align-content-end {
    align-content: flex-end;
  }

  &&-align-content-center {
    align-content: center;
  }

  &&-align-content-between {
    align-content: space-between;
  }

  &&-align-content-around {
    align-content: space-around;
  }

  &&-align-content-stretch {
    align-content: stretch;
  }

  & &-item {
    box-sizing: border-box;
    flex: 1;
    margin-left: @h-spacing-md;
    min-width: 10 * @hd;

    &:first-child {
      margin-left: 0;
    }
  }
}

.@{modalPrefixClass} {
  position: relative;
  &:not(.@{modalPrefixClass}-transparent):not(.@{modalPrefixClass}-popup) {
    width: 100%;
    height: 100%;
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: @modal-zindex;
    background-color: @fill-mask;

    &-hidden {
      display: none;
    }
  }

  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: @modal-zindex;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // fixed a layer issue with animated Tabs in the x5 kernel browser
    transform: translateZ(1px);
    &-popup {
      display: block;
    }
  }

  &-transparent {
    width: 270 * @hd;
    .@{modalPrefixClass}-content {
      border-radius: @radius-lg;
      padding-top: @v-spacing-lg;

      .@{modalPrefixClass}-body {
        padding: 0 @h-spacing-lg @v-spacing-lg;
      }
    }
  }

  &-popup {
    position: fixed;
    left: 0;
    width: 100%;

    &-slide-down {
      top: 0;
    }

    &-slide-up {
      bottom: 0;
    }

    .@{modalPrefixClass}-content {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  &-title {
    margin: 0;
    font-size: @modal-font-size-heading;
    line-height: @line-height-base;
    color: @color-text-base;
    text-align: center;
  }

  &-header {
    padding: @v-spacing-sm @h-spacing-lg @v-spacing-lg;
  }

  &-content {
    position: relative;
    background-color: @fill-base;
    border: 0;
    background-clip: padding-box;
    text-align: center;
    height: 100%;
    overflow: hidden;
  }

  &-close {
    border: 0;
    padding: 0;
    background-color: transparent;
    outline: none;
    position: absolute;
    right: @h-spacing-lg;
    z-index: 999;
    height: @icon-size-sm;
    width: @icon-size-sm;

    &-x {
      display: inline-block;
      width: 15 * @hd;
      height: 15 * @hd;
      background-repeat: no-repeat;
      background-size: cover;
      .encoded-svg-background('modal_delete');
    }
  }

  &-body {
    font-size: @font-size-subhead;
    color: @color-text-caption;
    height: 100%;
    line-height: @line-height-paragraph;
    overflow: auto;
  }
}

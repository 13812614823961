@import '../../style/themes/default';

@paginationPrefixCls: am-pagination;

.@{paginationPrefixCls} {
  &-wrap {
    font-size: 18 * @hd;
    color: @color-text-base;
    background: none;
    text-align: center;

    .active {
      color: @color-link;
    }

    &-btn {
      text-align: center;

      &-prev {
        text-align: left;
      }

      &-next {
        text-align: right;
      }
    }

    &-dot {
      display: inline-block;
      zoom: 1;

      > span {
        display: block;
        width: 8 * @hd;
        height: 8 * @hd;
        margin-right: @h-spacing-sm;
        border-radius: @radius-circle;
        background: @color-icon-base;
      }

      &-active {
        > span {
          background: #888;
        }
      }
    }
  }
}
